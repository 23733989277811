<template>
  <div class="marking__container container-with-tree">
    <section
      class="marking__scroll-container"
      :class="step2ScrollModifications"
    >
    <div class="marking__outer-container" :class="{'marking__outer-container--step2-modifications': steps[2]}">
      <div
        class="marking__preview-container"
        :class="previewDefaultClasses"
     
          :style="image !== '' ? { backgroundImage: `url(${image})` } : ''"
      >        
        <button
          class="btn__round marking__preview-btn"
          type="button"
          @click="handleOnCloseClick()"
        >
          <img src="../../assets/icons/close.svg" class="custom-svgs" alt="x" />
        </button>
        <div v-if="steps[3] && showTipMessages" class="marking__preview-tip-messages">
            <p v-for="(tipMessage) in tipMessages" :key="tipMessage">            
              {{ tipMessage }}
          </p>
        </div>
        <div v-if="steps[3]" class="marking__preview-image">
          <curtain-visualizer
            v-if="curtainVisualizerData"
            :data="curtainVisualizerData"
          />          
        </div>
      </div>

      <div
        class="marking__content-container"
        :class="[
          { 'marking__content-container--step3': steps[3] },
          { 'marking__content-container--step2': steps[2] },
        ]"
      >
        <div
          class="marking__breadcrumb-container"
          :class="{ 'marking__breadcrumb-container--step2': steps[2] }"
        >
          <ul class="breadcrumb">
            <li>{{ order.offerName }}</li>
            <li v-if="folder.parentId">{{ folder.name }}</li>
            <li>{{ marking.name }}</li>
          </ul>
        </div>

        <div v-if="steps[1]">
          <div class="input__container marking__edit-marking-input">
            <input
              name="newMarking"
              type="text"
              maxlength="20"
              v-model="name"
              class="input__icon input__icon--marking input input__size--large"
              required
            />
          </div>
        </div>
      
        <div v-show="steps[2]" class="product-selector__container">
          <h1>{{ $t("FabricSelector:Header") }}</h1>
          <div class="product-selector__container__external-switch">
            <p>{{ $t("FabricSelector:SubHeader") }}</p>
            <ToggleSwitch
              :labelOn="$t('FabricSelector:ExternalSupplier')"
              :labelOff="$t('FabricSelector:InternalSupplier')"
              :value="isExternalSupplier"
              @change="onExternalSupplierChange"
            />
          </div>
          <div v-if="!isExternalSupplier">
            <ProductSelector
              :order-line="orderLine"
              @select-variant="productSelectorSelectVariant"
              :is-next-button-enabled="isNextButtonEnabled"
            />
          </div> 
          <div v-if="isExternalSupplier">
          <ExternalFabricForm
            :externalFabricInfo="externalFabricInfo"
            @update:externalFabricInfo="handleExternalFabricInfoUpdate"
            @form-valid="handleFormValid"
            @select-variant="productSelectorSelectVariant"
          />
          </div> 
        </div>

        <div
          v-show="steps[3]"
          class="marking__configurator-container container-limited"
        >
          <FabricEditor
            :order-line="orderLine"
            @calculating="onFabricEditorCalculating"
            @calculated="onFabricEditorCalculated"
            :selected-variant="selectedVariant"
            :show="steps[3]"
            @show-tip-messages="onFabricEditorTipMessageShow"
          />
        </div>
      </div>

</div>

      <div
        class="wizard-bottom-bar__container"
        :class="{ 'bottom-bar__step-2-modifications': steps[2] }"
      >
        <ul class="wizard-bottom-bar__btn-group">
          <li v-for="(value, key) in steps" :key="key">
            <button
              class="btn__round"
              type="button"
              @click="changeStep(key)"
              :class="{ 'btn--active': key == currentStep }"
            >
              {{ key }}
            </button>
          </li>
        </ul>

        <div class="marking__go-back-btn">
          <button
            class="btn__round btn__with-icon--chevron-left btn__with-icon"
            @click="changeStep(currentStep - 1)"
          />
        </div>
        <div class="wizard-bottom-bar__right-container">
          <div v-if="currentStep === 3 && markingTotalPrice" class="wizard-bottom-bar__right-container__price">
            Totalt: <span class="">{{ markingTotalPrice.formatted }}</span>
          </div>

          <div>
            <button
              v-if="currentStep < 3"
              class="btn__default--primary"
              type="button"
              @click="handleNextClick"
              :disabled="!isNextButtonEnabled"
              :class="{ 'btn--disabled': !isNextButtonEnabled}"
            >
              {{ $t("Marking:NextButton") }}
            </button>
            <button
              class="btn__default--primary"
              :disabled="!saveEnabled"
              type="button"
              @click="saveMarking"
            >
              {{ $t("Marking:SaveButton") }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="./WizardSteps.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./WizardSteps.scss";
</style>
