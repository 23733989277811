<template>
  <div class="preview-order-table">
    <!-- Internal Markings Table -->
    <div class="table-container">
    <div v-if="internalFabricOrderLines.length">
      <div class="table-header">
        <div class="table-column"></div>
        <div class="table-column">{{ translate("PreviewOrder:Marking") }}</div>
        <div class="table-column">{{ translate("MarkingMenu:Fabrics") }}</div>
        <div class="table-column">{{ translate("PreviewOrder:SewingType") }}</div>
        <div class="table-column">{{ translate("PreviewOrder:UnderHem") }}</div>
      </div>
      <div class="table-body">
        <div
          class="table-row"
          v-for="({ marking, orderLine }, index) in internalFabricOrderLines"
          :key="index"
        >
          <!-- Main Row -->
          <div class="table-column column-1">
            
          </div>
          <div class="table-column column-2">
            <div class="marking-name">{{ marking.name }}</div>
            <button @click="toggleExpand(marking.id)">
              {{ orderLine?.curtainData?.curtainDrops?.length }} {{ translate("PreviewOrder:CurtainDropsListHeadingQtyMain") }}
              <img
                :src="expandedRows.includes(marking.id) ? ArrowUpIcon : ArrowDownIcon"
                alt="Arrow Icon"
              />
            </button>
          </div>
          <div class="table-column column-3">{{ orderLine?.productName }}</div>
          <div class="table-column column-4">
            {{ orderLine?.curtainData ? getSewingTypeName(orderLine.curtainData.sewingType) : '' }}
          </div>
          <div class="table-column column-5">{{ orderLine?.curtainData?.underHem }}</div>
          <div class="table-column column-6">
            <img :src="getMarkingImage(marking.id)" alt="icon" />
          </div>

          <!-- Expanded Details -->
          <div
            class="details-row"
            v-if="expandedRows.includes(marking.id)"
          >
            <div class="details-content">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ translate("OrderPdf:CurtainDropsListHeadingFinishedHeight") }}</th>
                    <th>{{ translate("OrderPdf:CurtainDropsListHeadingFinishedWidth") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(drop, i) in orderLine?.curtainData?.curtainDrops"
                    :key="i"
                  >
                    <td>{{ `${ translate("PreviewOrder:CurtainDropsItemPrefix") } ${i + 1}` }}</td>
                    <td>{{ drop.finishedHeightPerCurtainDrop }}</td>
                    <td>{{ drop.finishedWidthPerCurtainDrop }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    

    <!-- External Markings Table -->
    <div v-if="externalFabricOrderLines.length">
      <h2 class="external-fabric-heading">{{ translate("PreviewOrder:ExternalFabric") }}</h2>
      <div class="table-container">
        <div class="table-header">
          <div class="table-column"></div>
          <div class="table-column">{{ translate("PreviewOrder:Marking") }}</div>
          <div class="table-column">{{ translate("MarkingMenu:Fabrics") }}</div>
          <div class="table-column">{{ translate("PreviewOrder:SewingType") }}</div>
          <div class="table-column">{{ translate("PreviewOrder:UnderHem") }}</div>
        </div>
        <div class="table-body">
          <div
            class="table-row"
            v-for="({ marking, orderLine }, index) in externalFabricOrderLines"
            :key="index"
          >
            <!-- Main Row -->
            <div class="table-column column-1">
              <input type="checkbox" :value="orderLine.markingId" 
              v-model="selectedFabrics" />
            </div>
            <div class="table-column column-2">
              <div class="marking-name">{{ marking.name }}</div>
              <button @click="toggleExpand(marking.id)">
                {{ orderLine?.curtainData?.curtainDrops?.length }} {{ translate("PreviewOrder:CurtainDropsListHeadingQtyMain") }}
                <img
                  :src="expandedRows.includes(marking.id) ? ArrowUpIcon : ArrowDownIcon"
                  alt="Arrow Icon"
                />
              </button>
            </div>
            <div class="table-column column-3">{{ orderLine?.externalFabricName }}</div>
            <div class="table-column column-4">
              {{ orderLine?.curtainData ? getSewingTypeName(orderLine.curtainData.sewingType) : '' }}
            </div>
            <div class="table-column column-5">{{ orderLine?.curtainData?.underHem ?? '' }}</div>
            <div class="table-column column-6 external-fabric-icon">
              <img src="../assets/icons/fabric.svg" alt="External Fabric Icon" />
            </div>

            <!-- Expanded Details -->
            <div
              class="details-row"
              v-if="expandedRows.includes(marking.id)"
            >
              <div class="details-content">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ translate("OrderPdf:CurtainDropsListHeadingFinishedHeight") }}</th>
                      <th>{{ translate("OrderPdf:CurtainDropsListHeadingFinishedWidth") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(drop, i) in orderLine?.curtainData?.curtainDrops ?? []"
                      :key="i"
                    >
                      <td>{{ `Våd ${i + 1}` }}</td>
                      <td>{{ drop.finishedHeightPerCurtainDrop }}</td>
                      <td>{{ drop.finishedWidthPerCurtainDrop }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
</template>



<script lang="ts" src="./PreviewOrderTableView.ts"></script>
<style lang="scss" src="./PreviewOrderTableView.scss"></style>