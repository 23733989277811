import axios from "./index";
import {
  Order,
  OrderByStatus,
  CreateOrder,
  OrderLine,
  PlaceOrder,
  PlaceOrderResponse,
  FabricMarkingGroup,
  CopyOrder,
  SendOrderPdfResponse,
  CopyItem,
} from "@/models/ordermodels";

/* eslint @typescript-eslint/no-var-requires: "off" */
const fileDownload = require('js-file-download');

export const OrderService = {
  async createOrder(model: CreateOrder): Promise<Order> {
    return await axios.post("/api/app/order", model);
  },
  async updateOrder(model: Order): Promise<void> {
    return await axios.put("/api/app/order", model);
  },
  async setStatusToInitiated(id: string): Promise<void> {
    return await axios.post(`/api/app/order/setStatusToInitiated/${id}`);
  },
  async getOrder(id: string): Promise<Order> {
    return await axios.get(`/api/app/order/${id}`);
  },
  async getOrdersByStatus(): Promise<OrderByStatus> {
    return await axios.get("/api/app/order/bystatus");
  },
  async createOrderLine(orderId: string, orderLine: OrderLine): Promise<void> {
    return await axios.post(`/api/app/order/${orderId}/orderline`, orderLine);
  },
  async updateOrderLine(orderId: string, orderLine: OrderLine): Promise<void> {
    return await axios.put(`/api/app/order/${orderId}/orderline`, orderLine);
  },
  async getOrderPdf(orderId: string): Promise<Blob> {
    return axios
        .get(`/api/app/order/pdf/${orderId}`, {
            responseType: 'blob',
        });
  },
  async sendOrderPdf(orderId: string): Promise<SendOrderPdfResponse> {
    return axios.post(`/api/app/order/sendorderpdf/${orderId}`);
  },
  async sendExternalFabricsPdf(model: PlaceOrder): Promise<SendOrderPdfResponse> {
    return axios.post("/api/app/order/sendexternalfabricspdf", model);
  },
  async placeOrder(model: PlaceOrder): Promise<PlaceOrderResponse> {
    return await axios.post("/api/app/order/placeorder", model);
  },
  async searchOrders(searchText: string): Promise<Order[]> {
    return await axios.get("/api/app/order/bysearchtext", {
      params: {
        searchText
      }
    });
  },
  async createCopyOfOrder(model: CopyOrder): Promise<Order> {
    return await axios.post("/api/app/order/copy", model);
  },
  async getFabricMarkingList(orderId: string): Promise<FabricMarkingGroup[]> {
    return await axios.get(`/api/app/stormproductbuilder/fabricmarkinglist/${orderId}`);
  },
  async updateMarkingFabric(orderId: string, productId: number, oldErpProductId: number, newErpProductId: number): Promise<void> {
    return await axios.put(`/api/app/order/${orderId}`, { productId, oldErpProductId, newErpProductId });
  },
  async getOrderWithRefreshedPrices(id: string): Promise<Order> {
    return await axios.get(`/api/app/order/${id}/refreshedprices`);
  },
  async deleteOrders(orderIds: string[], userEmailAddress: string): Promise<void> {
    return await axios.post("/api/app/order/deleteorders", { orderIds, userEmailAddress});
  },
  async createCopyOfMarking(model: CopyItem): Promise<void> {
    return await axios.post("/api/app/order/markingcopy", model);
  },
  async createCopyOfFolder(model: CopyItem): Promise<void> {
    return await axios.post("/api/app/order/foldercopy", model);
  },
  async downloadOrderImages(orderId: string, fileName: string): Promise<void> {
      const data = await axios.get(`/api/app/order/orderimages/${orderId}`, { responseType: 'blob' });
      fileDownload(data, fileName);
  },
  async getWeekOfYear(dateTime: Date): Promise<number> {
    return await axios.get("/api/app/order/weekofyear", {
      params: {
        dateTime
      }
    });
  }
}