import { computed, defineComponent, ref, Ref } from "vue";
import { CopyOrder, CustomerInformation, Order, OrderStatus, PlaceOrder, SendExternalFabricsPdf } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import CustomerInfoForm from "./CustomerInfoForm.vue";
import { useI18n } from "vue-i18n";
import { OrderService } from "@/services/OrderService";
import { AccountStore } from "@/store/accountstate";
import { CurrentUser } from "@/models/accountmodels";
import { useRouter } from "vue-router";
import { UserFriendlyError } from "@/models/errormodels";
import swal from "sweetalert2";
import { GlobalizationService } from "@/services/GlobalizationService";
import { Country } from "@/models/Country";
import GoBack from '@/components/goBack/GoBack.vue'
import { UIStore } from "@/store/ui";
import PreviewOrderTableView from "@/components/PreviewOrderTableView.vue";

export default defineComponent({
    name: "PreviewOrder",

    components: {
        CustomerInfoForm,
        PreviewOrderTableView,
        GoBack
    },    

    async created() {
        const updateOrder = async (): Promise<Order> => {
            const order = OrderStore.order as Order;
            if (order.status == OrderStatus.Initiated || order.status == OrderStatus.OfferSentToCustomer) {
                return await OrderService.getOrderWithRefreshedPrices(order.id);
            } else {
                return order;
            }
        };
        const [order, countries] = await Promise.all([updateOrder(), GlobalizationService.getCountries()]);
        this.order = order;
        this.countryList = countries;
        UIStore.setLoading(false);
    },

    beforeRouteEnter() {
        UIStore.setLoading(true);
    },

    setup() {
        const router = useRouter();
        const translate = useI18n().t;
        const defaultCountry: string = "SE";
        const order = ref<Order>({} as Order);
        const countryList = ref<Country[]>([]);
        const showCustomerInformation = ref<boolean>(false);     
        const errorMessage = ref<string>();
        const emailSendResponseText = ref<string | null>(null);
        const currentUser = computed<CurrentUser>(() => AccountStore.currentUser as CurrentUser); 
        const customerInformation = computed<CustomerInformation>(() => {
            if (!currentUser.value) return {
                customersOrderNumber: "",
                requestedDeliveryDate: "",
                deliveryAddressCompanyName: "",
                address1: "",
                address2: "",
                zipcode: "",
                city: "",
                country: defaultCountry,
                cellPhone: "",
                mobile: "",
                email: "",
                company: "",
                firstName: "",
                lastName: ""
            };

            return {
                customersOrderNumber: "",
                requestedDeliveryDate: "",
                deliveryAddressCompanyName: "",
                address1: currentUser.value?.address || "",
                address2: "",
                zipcode: currentUser.value?.postalNo || "",
                city: currentUser.value?.city || "",
                country: currentUser.value?.countryCode || defaultCountry,
                cellPhone: currentUser.value?.phoneNumber || "",
                mobile: currentUser.value?.mobile || "",
                email: currentUser.value?.email || "",
                company: currentUser.value?.erpCompanyName || "",
                firstName: currentUser.value?.firstName || "",
                lastName: currentUser.value?.lastName || ""
            };
          });

        const orderStatusInitiated = computed(() => order.value.status === OrderStatus.Initiated);
        const showChangeOrder = computed(() => order.value.status === OrderStatus.OfferSentToCustomer && !currentUser.value.internalSalesSven);
        const orderStatusSentOrPlaced = computed(() => order.value.status == OrderStatus.OfferSentToCustomer || order.value.status == OrderStatus.OrderPlaced);        
        const showCopyOrder = computed(() => !currentUser.value.internalSalesSven && (order.value.status == OrderStatus.OfferSentToCustomer || order.value.status == OrderStatus.OrderPlaced));
        const showBackButton = computed(() => orderStatusSentOrPlaced);
        const showPlaceOrder = ref<boolean>(false);
        const showOrderTotalDiscountAmount = computed(() => (order.value.orderTotalDiscountAmount.value ?? 0) > 0);
        const showEmailSendResponse = computed(() =>  !!emailSendResponseText.value )
        const showHideCustomerInformation = (): void => { showCustomerInformation.value = !showCustomerInformation.value; }
        const closeEmailSendResponseBox = (): void => { emailSendResponseText.value = null; }
        const showDownloadOrderImages = computed(() => (order.value.folder.markings.flatMap(x=>x.images).length + order.value.folder.folders.flatMap(x=>x.markings).flatMap(m=>m.images).length) > 0);
        const weekNumber = ref<number>();
        const callbackFunction: Ref<(() => void | Promise<void>) | undefined> = ref(undefined);
        const isExternalSelected = ref<boolean>(false);
        const hasExternalFabrics = computed(() => order.value.hasExternalFabrics);
        const selectedExternalFabrics = ref<string[]>([]);
        const hasExternalFabricsSelected = computed(() => selectedExternalFabrics.value.length > 0);
        const showSendOrderPdf = computed(() => {
            return order.value.status === OrderStatus.Initiated && !currentUser.value.internalSalesSven && hasInternalFabrics();
        });        
        const showPlaceOrderStep1ShowDeliveryAddress = computed(() => {
            return order.value.status == OrderStatus.OfferSentToCustomer && currentUser.value.internalSalesSven && hasInternalFabrics();
        });

        function hasInternalFabrics() {
            return order.value.orderLines.some(
                (orderLine) => (orderLine && !orderLine.isExternalFabric) == true
            );
        }

        async function placeOrder(): Promise<void> {         
            const model: PlaceOrder = {
                orderId: order.value.id,
                customerInformation: customerInformation.value
            };
            errorMessage.value = undefined;
            try {
                await OrderService.placeOrder(model);
                await OrderService.loadOrder(order.value.id);
                router.push({ name: "previewOrderComplete", params: { order: order.value.id } });
            } catch (error) {
                const details = error as UserFriendlyError;
                errorMessage.value = details.message;
            }
        }

        async function createOrderCopy(): Promise<void> {
            errorMessage.value = undefined;
            const response = await swal.fire({
                title: translate("PreviewOrder:NameYourCopy"),
                input: "text",
                inputValue: order.value.offerName,
                showCancelButton: true,
                focusCancel: true,
                confirmButtonText: translate("PreviewOrder:ConfirmCopy"),
                cancelButtonText: translate("PreviewOrder:CancelCopy")
            });

            if (response.isConfirmed) {
                try {
                    UIStore.setLoading(true);
                    const request: CopyOrder = {
                        orderId: order.value.id,
                        offerName: response.value as string
                    };
                    const newOrder = await OrderService.createCopyOfOrder(request);
                    if (newOrder.ecomBasketId == null) {
                        errorMessage.value = "Order is too old for copying. Only Orders with order Ids higher than: " + newOrder.offerNumber + " are allowed to be copied"
                    }
                    else {
                        await router.push({ name: "order", params: { order: newOrder.id } });
                    }
                } catch (error) {
                    errorMessage.value = (error as UserFriendlyError).message;
                } finally {
                    UIStore.setLoading(false);
                }
            }
        }

        async function updateWeekNumber(e: InputEvent): Promise<void> {
          const element = (e.target as HTMLInputElement);
          const weekOfYear = await OrderService.getWeekOfYear(new Date(element.value));
          weekNumber.value = weekOfYear;
        }

        async function downloadOrderImages(): Promise<void> {
            try {
                UIStore.setLoading(true);
                const fileName = `Offer-${order.value.offerNumber}-${order.value.offerName}.zip`;
                await OrderService.downloadOrderImages(order.value.id, fileName);
            } catch (error) {
                errorMessage.value = (error as UserFriendlyError).message;
            } finally {
                UIStore.setLoading(false);
            }
        }

        async function sendOrderPdf(): Promise<void> {
            let htmlString = translate("OrderPdf:SendOrderPdfConfirmHtml");
            if (currentUser.value.email.toLowerCase() !== order.value.userEmailAddress.toLowerCase()) {
                htmlString = htmlString.replace("[emailAddress]", order.value.userEmailAddress + ", " + currentUser.value.email)
            } else {
                htmlString = htmlString.replace("[emailAddress]", order.value.userEmailAddress)
            }
            const confirm = await swal.fire({
                title: translate("OrderPdf:SendOrderPdfConfirmTitle"),
                html: htmlString,
                showCancelButton: true,
                focusCancel: true,
                confirmButtonText: translate("OrderPdf:SendOrderPdfConfirmButton"),
                confirmButtonAriaLabel: translate("OrderPdf:SendOrderPdfConfirmButton"),
                cancelButtonText: translate("OrderPdf:SendOrderPdfCancelButton"),
                cancelButtonAriaLabel: translate("OrderPdf:SendOrderPdfCancelButton")
            });
            if (confirm.isConfirmed) {
                UIStore.setLoading(true);
                const response = await OrderService.sendOrderPdf(order.value.id);
                emailSendResponseText.value = translate(response.message);
                router.push({ name: "previewOrderComplete" });
                UIStore.setLoading(false);
            }
        }

        async function setCallBackFunctionAndShowHideCustomerInfo(isExternal: boolean = false) {            
            if (isExternal) {
                if (hasExternalFabricsSelected.value){
                    assignCallbackToSendExternalFabricsPdf();
                    showHideCustomerInformation();
                } 
                else {                    
                    emailSendResponseText.value = translate("PreviewOrder:NoSelectedExternalFabrics");
                }             
            } 
            else {
                assignCallbackToPlaceOrder();
                showHideCustomerInformation();
            }
        }

        function assignCallbackToSendExternalFabricsPdf() {
            callbackFunction.value = sendExternalFabricsPdf;
            isExternalSelected.value = true;
          }
          
          function assignCallbackToPlaceOrder() {
            callbackFunction.value = placeOrder;
            isExternalSelected.value = false;
          }

        async function executeCallback() {
            if (callbackFunction.value) {
              await callbackFunction.value(); 
            }
          }

        async function sendExternalFabricsPdf(): Promise<void> {
            UIStore.setLoading(true);
            const model: SendExternalFabricsPdf = {
                orderId: order.value.id,
                customerInformation: customerInformation.value,
                selectedExternalFabricMarkingIds: selectedExternalFabrics.value
            };
            const response = await OrderService.sendExternalFabricsPdf(model);
            emailSendResponseText.value = translate(response.message);
            UIStore.setLoading(false);            
            showHideCustomerInformation();
            selectedExternalFabrics.value = [];            
        }

        async function changeOrder(): Promise<void> {
            errorMessage.value = undefined;
            try {
                await OrderService.setStatusToInitiated(order.value.id);
                await OrderService.loadOrder(order.value.id);
                router.push({ name: "order", params: { order: order.value.id } });
            } catch (error) {
                const details = error as UserFriendlyError;
                errorMessage.value = details.message;
            }
        }

        return {
            order,
            translate,
            orderStatusInitiated,
            showChangeOrder,
            orderStatusSentOrPlaced,
            showPlaceOrderStep1ShowDeliveryAddress,
            showCopyOrder,
            showBackButton,
            placeOrder,
            currentUser,
            customerInformation,
            showPlaceOrder,
            sendOrderPdf,
            emailSendResponseText,
            showEmailSendResponse,
            closeEmailSendResponseBox,
            errorMessage,
            createOrderCopy,
            countryList,
            showOrderTotalDiscountAmount,
            showSendOrderPdf,
            changeOrder,
            downloadOrderImages,
            showDownloadOrderImages,
            weekNumber,
            updateWeekNumber,
            executeCallback,
            setCallBackFunctionAndShowHideCustomerInfo,
            callbackFunction,
            showCustomerInformation,
            isExternalSelected,
            hasExternalFabrics,
            selectedExternalFabrics,
            hasExternalFabricsSelected,
            showHideCustomerInformation
        };
    }
});
