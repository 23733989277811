<template>
  <div class="external-fabric-form">
    <form>
      <div class="form-grid">
        <div class="form-group">
          <label for="supplier" class="form-label">{{ $t("ExternalFabricForm:Supplier") }}</label>
          <input
            type="text"
            id="supplier"
            class="input-field"
            v-model="formState.supplier"
          />
        </div>

        <div class="form-group">
          <label for="externalFabricName" class="form-label">{{ $t("ExternalFabricForm:ProductName") }}</label>
          <input
            type="text"
            id="externalFabricName"
            class="input-field"
            v-model="formState.externalFabricName"
          />
        </div>

        <div class="form-group">
          <label for="article-number" class="form-label">{{ $t("ExternalFabricForm:ArticleNumber") }}</label>
          <input
            type="text"
            id="article-number"
            class="input-field"
            v-model="formState.articleNumber"
          />
        </div>

        <div class="form-group">
          <label for="color" class="form-label">{{ $t("ExternalFabricForm:Color") }}</label>
          <input
            type="text"
            id="color"
            class="input-field"
            v-model="formState.color"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ $t("ExternalFabricForm:Width") }}</label>
        <div class="radio-group">
          <label class="radio__input-container">
            <input
              type="radio"
              value="150"
              v-model="formState.width"
            />
            <span class="radio__checkmark"></span>
            {{ $t("ExternalFabricForm:Width150") }}
          </label>
          <label class="radio__input-container">
            <input
              type="radio"
              value="300"
              v-model="formState.width"
            />
            <span class="radio__checkmark"></span>
            {{ $t("ExternalFabricForm:Width300") }}
          </label>
        </div>
      </div>
    </form>
  </div>
</template>


<script src="./ExternalFabricForm.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./ExternalFabricForm.scss";
</style>