import { Price } from "./ordermodels";

export enum SewTogetherLoadStatus {
    FirstTimeLoaded = 0,
    CanAutoLoad = 1,
    CanNotAutoLoad = 2
}

export enum ConfigurationType {
    Simple = 0,
    CoverWall = 1
}

export enum SewingType {
    Wave80 = 0,
    Wave60 = 1,
    GliderSinglePleat = 2,
    CurtainHeaderTape = 3,
    Glider = 4,
}

export enum CurtainOrientationType {
    Lying = 0,
    Upright = 1
}

export interface CurtainParameter {
    id: string;
    name: string;
    unit: number;
    value: number;
}

export interface CalculateCurtainDropsApiInput {
    fabricName: string;
    fabricWidth: number;
    fabricReport: number;
    fabricRecommendWeights: boolean;
    curtainConfiguration: number;
    quantity: number;
    sewingType: number;
    wallHeight: number;
    wallWidth: number;
    orientation: number;
    heightDeduction: number;
    sewTogether: boolean;
    splitCurtain: boolean;
    useWeights: boolean;
    preferredOrientation: string | null;
}

export interface FabricEditorSelectedData {
    curtainConfiguration: ConfigurationType;
    wallHeight: number;
    wallWidth: number;
    heightDeduction: number;
    sewTogether: boolean;
    splitCurtain: boolean;
    useWeights: boolean;
}

export interface CalculateCurtainDropsApiOutput {
    statusMessage: string;
    totalQuantity: number;
    markingQuantity: number;
    totalPriceForMarking: Price;
    sewingType: SewingType;
    totalExtraWideAddition: number;
    totalFabricLengthNeeded: number;
    underHem: number;
    totalWidthCovered: number;
    totalNumberOfWeights: number;
    totalCurtainDropQuantity: number;
    finishedHeight: number;
    numberOf2FingerHooks: number;
    numberOf4FingerHooks: number;
    totalFinishedWidth: number;
    sewTogether: boolean;
    splitCurtain: boolean;
    orientation: CurtainOrientationType;
    curtainDrops: CurtainDrop[];
    tipMessages: string[];
}

export interface CurtainDrop {
    quantity: number;
    finishedWidthPerCurtainDrop: number;
    extraWideAdditionPerCurtainDrop: number;
    weightsPerCurtainDrop: number;
    cutMeasurePerCurtainDrop: number;
    finishedHeightPerCurtainDrop: number;
    pricePerCurtainDrop: Price;
    vatPerCurtainDrop: Price;
}

export interface MarkingPriceRequest {
    orderId: string;
    markingId: string;
    markingQuantity: number;
    erpProductId: number | null;
    erpConfigurableProductId: number;
    curtainConfiguration: number;
    finishedHeight: number;
    numberOf2FingerHooks: number;
    numberOf4FingerHooks: number;
    sewingType: number;
    orientation: number;
    sewTogether: boolean;
    splitCurtain: boolean;
    curtainDrops: CurtainDrop[];
    currencyCode: string,
    underHem: number
}

export interface MarkingPrice {
    totalPrice: Price;
    priceLines: PriceLine[];
}

export interface PriceLine {
    price: Price;
}

export interface GeneralSettingsOutputDto
{
    showMaintenancePage: boolean;
    externalFabricProductId: number | null;
}