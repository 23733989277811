import { defineComponent, PropType } from "vue";
import { CustomerInformation } from "@/models/ordermodels";
import { Country } from "@/models/Country";
import { UIStore } from "@/store/ui";

export default defineComponent({
    name: "CustomerInfoForm",

    props: {
        customerInformation: {
            type: Object as PropType<CustomerInformation>,
            required: true,
            default: () => ({
                customersOrderNumber: "",
                requestedDeliveryDate: "",
                deliveryAddressCompanyName: "",
                address1: "",
                address2: "",
                zipcode: "",
                city: "",
                country: "",
                cellPhone: "",
                mobile: "",
                email: "",
                company: "",
                firstName: "",
                lastName: ""
              }),
        },
        weekNumber: {
            type: Number,
            required: true,
        },       
        countryList: {
            type: Array as PropType<Country[]>,
            required: true,
        },
        updateWeekNumber: {
            type: Function as PropType<(data: any) => void>,
            required: true,
          },
        callbackFunction: {
            type:  Function as PropType<() => void | Promise<void>>,
            required: true,
          },
        isExternalSelected: {
            type: Boolean,
            required: true,
        },
    },
    beforeRouteEnter() {
        UIStore.setLoading(true);
    },
    setup() {
        return {
            
        };
    }
});
