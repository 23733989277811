import { Marking, MarkingSummary, Order, OrderLine } from "@/models/ordermodels";
import { SewingType } from "@/models/configuratormodels";

export const MarkingService = {
    getMarkingSummary(marking: Marking, order: Order, translations: { (key: string | number): string }): MarkingSummary {

        const getOrderLine = (marking: Marking): OrderLine => {
            let orderLine = order.orderLines.find(x => x.markingId === marking.id);
            if (orderLine === undefined) {
                orderLine = this.createEmptyOrderLine(marking);
                order.orderLines.push(orderLine);
            }
            return orderLine;
        }

        const orderLine = getOrderLine(marking);

        let quantity = orderLine.quantity ?? 0;
        if ((orderLine?.markingQuantity ?? 0) > 0) {
            quantity = orderLine.markingQuantity ?? 0;
        }

        const markingSummary: MarkingSummary = {
            curtainConfiguration: orderLine.curtainConfiguration,
            markingName: marking.name,
            sewingType: "",
            productName: orderLine.productName,
            externalFabricName: orderLine.externalFabricName,
            isExternalFabric: orderLine.isExternalFabric,
            price: orderLine.price,
            totalFinishedWidth: orderLine.curtainData?.totalFinishedWidth ?? 0,
            finishedHeight: orderLine.curtainData?.finishedHeight ?? 0,
            quantity: quantity,
            curtainDrops: [],            
            underHem: orderLine.curtainData?.underHem ?? 0
        };

        if (orderLine.curtainData != null) {
            if (Object.values(SewingType).includes(orderLine.curtainData.sewingType)) {
                markingSummary.sewingType = translations("ConfiguratorEditor:SewingType" + SewingType[orderLine.curtainData.sewingType] + "Label");
            }
            markingSummary.curtainDrops = orderLine.curtainData.curtainDrops.map(curtainDrop => ({
                quantity: curtainDrop.quantity,
                finishedHeightPerCurtainDrop: curtainDrop.finishedHeightPerCurtainDrop,
                finishedWidthPerCurtainDrop: curtainDrop.finishedWidthPerCurtainDrop
            }));
        }

        return markingSummary;
    },

    createEmptyOrderLine(marking: Marking) {
        return {
            ecomProductId: 0,
            ecomRowId: 0,
            quantity: 0,
            markingId: marking.id,
            markingName: marking.name,
            erpConfigurableProductId: 0,
            price: { value: 0, formatted: "" },
            recommendWeights: false,
            productName: "",
            productWidth: 0,
            productRepeatHeight: 0,
            curtainConfiguration: 0,
            wallHeight: 0,
            useWeights: false
        } as OrderLine;
    },
} as const;
