import axios from "./index";
import { ProductModel, ProductResponse } from "@/models/stormmodels";

export const StormService = {
  async getFilters() : Promise<ProductResponse> {
    return await axios.get("/api/app/stormproductbuilder/productfilters");
  },
  async getProducts(searchString?: string, filter?: string): Promise<ProductModel[]> {
    return await axios.get("/api/app/stormproductbuilder/productsforhangingfabrics", {
      params: {
        searchString,
        filter
      }
    });
  },
  async getVariants(productId: number): Promise<ProductModel[]> {
    return await axios.get(`/api/app/stormproductbuilder/variantsforhangingfabrics/${productId}`);
  },
  async getExternalFabricVariant(): Promise<ProductModel> {
    return await axios.get("/api/app/stormproductbuilder/externalfabricvariant");
  },
};
