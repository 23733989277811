import { Vue, Options } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { StormService } from "@/axios/stormservice";
import { ProductModel } from "@/models/stormmodels";
import { OrderLine } from "@/models/ordermodels";

@Options({
    emits: [
        "select-variant"
    ]
})
export default class VariantSelector extends Vue {

    @Prop()
    protected product!: ProductModel;

    @Prop()
    protected orderLine!: OrderLine;

    @Prop()
    protected isNextButtonEnabled!: boolean;

    @Watch("product")
    protected async watchProductChange(product: ProductModel): Promise<void> {
        await this.loadVariants(product.productId);
    }

    protected variants: ProductModel[] = [];

    async created(): Promise<void> {
        if (this.orderLine && this.orderLine.ecomProductId > 0 && this.orderLine.erpProductId) {
            if (!this.orderLine.isExternalFabric) {
            await this.loadVariants(this.orderLine.ecomProductId, this.orderLine.erpProductId, true);
            }
            else {
                await this.loadVariants(this.product.productId);
            }
        } else {
            await this.loadVariants(this.product.productId);
        }
    }

    protected selectVariant(variant: ProductModel): void {
        this.variants.forEach(x => x.active = false);
        variant.active = true;
        this.$emit("select-variant", variant);
    }

    private async loadVariants(productId: number, partNo: number | null = null, isDirty : boolean | null = false): Promise<void> {
        try {
            this.variants = await StormService.getVariants(productId);
            if (this.variants.length > 0) {
                let variant: ProductModel | undefined;
                if (partNo) {
                    variant = this.variants.find(x => x.partNo === partNo) ?? this.variants[0];
                } else {
                    variant = this.variants[0];
                }
                this.selectVariant(variant);
                variant.isDirty = isDirty ?? false;
            }
        } catch (error) {
            console.error(error);
        }
    }
}
