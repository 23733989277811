import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { watch, reactive } from "vue";
import { ProductModel } from "@/models/stormmodels";
import { StormService } from "@/axios/stormservice";
import { GeneralSettingsService } from "@/axios/generalsettingsservice";

@Options({
  emits: ["form-valid", "update:externalFabricInfo", "select-variant"],
  props: {
    externalFabricInfo: {
      type: Object,
      required: true,
    },
  },
})
export default class ExternalFabricForm extends Vue {
  @Prop() readonly externalFabricInfo!: Record<string, any>;

  private selectedVariant: ProductModel | null = null;
  private externalFabricProductId: number | null = null;

  private formState = reactive({
    supplier: this.externalFabricInfo.supplier || "",
    externalFabricName: this.externalFabricInfo.externalFabricName || "",
    articleNumber: this.externalFabricInfo.articleNumber || "",
    color: this.externalFabricInfo.color || "",
    width: this.externalFabricInfo.width || "150",
  });

  private validateForm(): boolean {
    const isValid = !!(
      this.formState.supplier &&
      this.formState.externalFabricName &&
      this.formState.articleNumber &&
      this.formState.color &&
      this.formState.width
    );
    this.$emit("form-valid", isValid); // Emit validation status
    return isValid;
  }

  private emitValidation(): void {
    const isValid = this.validateForm();
    this.$emit("form-valid", isValid);
    this.$emit("update:externalFabricInfo", { ...this.formState }); // Send update state
  }

  private productSelectorSelectVariant(variant: ProductModel, userInitiated: boolean): void {
    this.$emit("select-variant", variant, userInitiated);
  }

  protected async getExternalFabricProductId(): Promise<void> {
    try {
        const data = await GeneralSettingsService.getGeneralSettings();
        if (data !== undefined) {
            this.externalFabricProductId = Number(data.externalFabricProductId);
        } 
    }
    catch (error){
        console.log(error)
    }
  }

  public async getExternalFabricVariant(): Promise<void> {
    try {
      const variant = await StormService.getExternalFabricVariant();
  
      if (variant) {
        // Update variant with data from externalFabricInfo
        this.selectedVariant = {
          ...variant,
          externalFabricName: this.formState.externalFabricName,
          width: parseInt(this.formState.width),
          color: this.formState.color,
          externalFabricSupplier: this.formState.supplier,
          externalFabricArticleNumber : this.formState.articleNumber, 
          isExternalFabric : true
        };
        // Send the updated variant to the parent
        this.productSelectorSelectVariant(this.selectedVariant, true);
      } else {
        console.error("No variant found");
      }
    } catch (error) {
      console.error("Error fetching variant:", error);
    }
  }

  private handleFormStateChange(): void {
    const isValid = this.validateForm();
    if (isValid) {
      this.getExternalFabricVariant(); // Get externalFabricVariant when form is valid
    }
    this.emitValidation();  // Validate every change
  }
  
  created(): void {
    this.getExternalFabricVariant();
    watch(
      () => this.formState,
      this.handleFormStateChange,
      { deep: true }
    );
  }
  
  mounted(): void {
    this.emitValidation(); // Initial validation
  }
}
