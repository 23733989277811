import { defineComponent, computed, ref, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { Folder, Order, OrderLine } from "@/models/ordermodels";
import { SewingType } from "@/models/configuratormodels";
import { OrderStore } from "@/store/orderstate";
import ArrowUpIcon from "@/assets/icons/arrowhead-up.svg";
import ArrowDownIcon from "@/assets/icons/arrowhead-down.svg";


export default defineComponent({
  name: "PreviewOrderTableView",
  props: {
    folder: {
      type: Object as () => Folder,
      required: true,
    },
    selectedExternalFabrics: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    }
  },
  emits: ["update:selectedExternalFabrics"],
  setup(props , { emit }) {
    const translate = useI18n().t;
    const expandedRows = ref<string[]>([]);

    const selectedFabrics = computed({
      get() {
        return props.selectedExternalFabrics;
      },
      set(newValue: string[]) {
        emit("update:selectedExternalFabrics", newValue);
      }
    });

    const order = computed<Order>(() => {
      const currentOrder = OrderStore.order;
      if (!currentOrder) {
        throw new Error("Order not found");
      }
      return currentOrder;
    });

    const getOrderLine = (markingId: string): OrderLine | undefined => {
      const orderLine = order.value.orderLines.find(
        (line) => line.markingId === markingId
      );
      return orderLine;
    };

    const getSewingTypeName = (type: SewingType): string => {
      return translate(
        `ConfiguratorEditor:SewingType${SewingType[type]}Label`
      );
    };

    const getMarkingImage = (markingId: string): string | undefined => {
      return order.value?.fabricMarkingList
        .find((x) => x.markings.find((y) => y.markingId === markingId))
        ?.variantImage;
    };

    const markingOrderLines = computed(() => {
      return Array.from(props.folder.markings.values()).map((marking) => {
        return { marking, orderLine: getOrderLine(marking.id) };
      });
    });

    const internalFabricOrderLines = computed(() => {
      return markingOrderLines.value.filter(
        ({ orderLine }) => orderLine && !orderLine.isExternalFabric
      );
    });

    const externalFabricOrderLines = computed(() => {
      return markingOrderLines.value.filter(
        ({ orderLine }) => orderLine && orderLine.isExternalFabric
      );
    });

    const toggleExpand = (markingId: string): void => {
      if (expandedRows.value.includes(markingId)) {
        expandedRows.value = expandedRows.value.filter((id) => id !== markingId);
      } else {
        expandedRows.value.push(markingId);
      }
    };

    return {
      markingOrderLines,
      internalFabricOrderLines,
      externalFabricOrderLines,
      getSewingTypeName,
      getMarkingImage,
      toggleExpand,
      expandedRows,
      ArrowDownIcon,
      ArrowUpIcon,
      translate,
      selectedFabrics
    };
  },
});
