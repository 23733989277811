import { Order, CreateMarking, Marking, CreateFolder, Folder, MarkingImage, OrderLine, PlaceOrder, PlaceOrderResponse, CopyOrder, SendOrderPdfResponse, CopyItem } from "@/models/ordermodels";
import { OrderService as OrderApi } from "@/axios/orderservice";
import { MarkingClient } from "@/axios/markingclient";
import { OrderStore } from "@/store/orderstate";
import { FolderService } from "@/axios/folderservice";
import { SelectedItem } from "@/store/changestate";

export const OrderService = {
    async loadOrder(id: string): Promise<void> {
        const order = await this.getOrder(id);
        OrderStore.setOrder(order);
        return Promise.resolve();
    },
    async getOrderWithRefreshedPrices(id: string): Promise<Order> {
        const order = await OrderApi.getOrderWithRefreshedPrices(id);
        order.folders = buildFolderMap(order);
        order.markings = buildMarkingMap(order);
        OrderStore.setOrder(order);
        return order;
    },
    async getOrder(id: string): Promise<Order> {
        const order = await OrderApi.getOrder(id);
        order.folders = buildFolderMap(order);
        order.markings = buildMarkingMap(order);
        return order;
    },
    async updateOrder(model: Order) : Promise<void> {
        return await OrderApi.updateOrder(model);
    },
    async setStatusToInitiated(id: string) : Promise<void> {
        return await OrderApi.setStatusToInitiated(id);
    },
    async createMarking(model: CreateMarking): Promise<Marking> {
        return await MarkingClient.createMarking(model);
    },
    async updateMarking(model: Marking): Promise<void> {
        return await MarkingClient.updateMarking(model);
    },
    async updateMarkingName(orderId: string, id: string, name:string): Promise<void> {
        return await MarkingClient.updateMarkingName(orderId, id, name);
    },
    async getMarkingImages(id: string): Promise<MarkingImage[]> {
        return await MarkingClient.getImages(id);
    },
    async updateMarkingImage(model: MarkingImage): Promise<void> {
        return await MarkingClient.updateImage(model);
    },
    async deleteMarkingImage(id: string): Promise<void> {
        return await MarkingClient.deleteImage(id);
    },
    async createOrderLine(orderId: string, orderLine: OrderLine) : Promise<void> {
        return await OrderApi.createOrderLine(orderId, orderLine);
    },
    async updateOrderLine(orderId: string, orderLine: OrderLine) : Promise<void> {
        return await OrderApi.updateOrderLine(orderId, orderLine);
    },
    async deleteMarking(markingId: string): Promise<void> {
      return await MarkingClient.deleteMarking(markingId);
    },
    async createFolder(model: CreateFolder): Promise<Folder> {
      return await FolderService.createFolder(model);
    },
    async deleteFolder(folderId: string): Promise<Folder> {
      return await FolderService.deleteFolder(folderId);
    },
    async placeOrder(model: PlaceOrder): Promise<PlaceOrderResponse> {
      return await OrderApi.placeOrder(model);
    },
    async createCopyOfOrder(model: CopyOrder): Promise<Order> {
      return await OrderApi.createCopyOfOrder(model);
    },
    async getOrderPdf(orderId: string): Promise<Blob> {
        return await OrderApi.getOrderPdf(orderId);
    },
    async sendOrderPdf(orderId: string): Promise<SendOrderPdfResponse> {
        return await OrderApi.sendOrderPdf(orderId);
    },
    async sendExternalFabricsPdf(model: PlaceOrder): Promise<SendOrderPdfResponse> {
        return await OrderApi.sendExternalFabricsPdf(model);
    },
    async updateMarkingFabric(orderId: string, productId: number, oldVariantId: number, newVariantId: number) : Promise<void> {
        return await OrderApi.updateMarkingFabric(orderId, productId, oldVariantId, newVariantId);
    },
    async updateFolder(id: string, name:string): Promise<void> {
        return await FolderService.updateFolder(id, name);
    },
    async createCopyOfFolder(model: CopyItem, orderId: string): Promise<string> {
      await OrderApi.createCopyOfFolder(model);
      await this.loadOrder(orderId);
      return model.name
    },
    async createCopyOfMarking(model: CopyItem, orderId: string): Promise<string> {
      await await OrderApi.createCopyOfMarking(model);
      await this.loadOrder(orderId);
      return model.name
    },
    async updateMarkingNameAndLoadOrder(id: string, name: string, orderId: string): Promise<string> {
      await MarkingClient.updateMarkingName(orderId, id, name);
      await this.loadOrder(orderId);
      return name;
    },
    async updateFolderNameAndLoadOrder(id: string, name: string, orderId: string): Promise<string> {
      await FolderService.updateFolder(id, name);
      await this.loadOrder(orderId);
      return name;
    },
    async deleteFoldersAndMarkings(folders: SelectedItem[], markings: SelectedItem[], orderId: string): Promise<void> {
      if (folders.length > 0) {
        for (let i = 0; i < folders.length; i++) {
          await FolderService.deleteFolder(folders[i].id);
        }
      }
      if (markings.length > 0) {
        for (let i = 0; i < markings.length; i++) {
          await MarkingClient.deleteMarking(markings[i].id, false);
        }
      }
      if (folders.length > 0 || markings.length > 0) { 
        await this.loadOrder(orderId);
      }
    },
    async downloadOrderImages(orderId: string, fileName: string): Promise<void> {
        await OrderApi.downloadOrderImages(orderId, fileName);
    },
    async getWeekOfYear(dateTime: Date): Promise<number> {
      return await OrderApi.getWeekOfYear(dateTime);
    }
} as const;

function buildFolderMap(order: Order): Map<string, Folder> {
    const arr = order.folder.folders.reduce((acc: Folder[], key: Folder) => {
        return acc.concat(key.folders, key);
    }, []).concat(order.folder);

    return new Map(arr.map(x => [x.id, x]));
}

function buildMarkingMap(order: Order): Map<string, Marking> {
    const arr = order.folder.folders.reduce((acc: Marking[], key: Folder) => {
        return acc.concat(key.markings);
    }, []).concat(order.folder.markings);
    for (const marking of arr) {
        const image = order.fabricMarkingList.find(x => x.markings.find(x => x.markingId === marking.id))?.variantImage;
        marking.productImage = image;
    }

    return new Map(arr.map(x => [x.id, x]));
}
