<template>
  <div
    v-if="order.id"
    class="preview-order container-with-tree "
    :class="{ 'preview-order-modifications': orderStatusSentOrPlaced }"
  >
    <div class="preview-order__outer-container">
      <div v-if="orderStatusInitiated" class="preview-order__close">
        <go-back
          title="Beställ"
          routeName="order"
          :routeParams="{ order: order.id }"
        />
        <router-link :to="{ name: 'order' }" class="preview-order__close-btn">
          <button
            class="btn__round btn__with-icon btn__with-icon--close"
          ></button>
        </router-link>
      </div>
      <div v-else>
        <div v-if="currentUser.internalSalesSven" class="preview-order__close-btn">
          <go-back :title="order.offerName" routeName="searchorders" />
        </div>
        <div v-else>
          <go-back :title="order.offerName" routeName="orders" />
        </div>
      </div>

      <div class="preview-order__inner-container">
        <div class="container-limited--side-img">
          <h1 v-if="orderStatusSentOrPlaced">
            {{ translate("PreviewOrder:HeaderWhenOrderPlaced") }}
          </h1>
          <div class="preview-order__about-order">
            <h1 v-if="orderStatusInitiated" class="preview-order__header">
              {{ translate("PreviewOrder:HeaderWhenInitiated") }}
            </h1>
            <div>
              {{
                `${translate("PreviewOrder:OrderNumber")}: ${order.offerNumber}`
              }}
            </div>
            <div v-show="order.customerOrderNumber">
              {{
                `${translate("PreviewOrder:CustomerOrderNumber")}: ${order.customerOrderNumber}`
              }}
            </div>
            <div v-if="order.offerSentToCustomerAtFormatted">
              {{
                `${translate("PreviewOrder:OrderDate")}:  ${
                  order.offerSentToCustomerAtFormatted
                }`
              }}
            </div>
          </div>

          <preview-order-table-view :folder="{ markings: order.markings }" v-model:selectedExternalFabrics="selectedExternalFabrics"/>

          <div class="preview-order__details-container" v-if="order.orderTotalNetAmount.value > 0">
            <ul class="preview-order__details-list">
              <li>
                <span> {{ translate("PreviewOrder:OrderTotalNetAmountLabel") }}: </span>
                <span> {{ order.orderTotalNetAmount.formatted }} </span>
              </li>
              <li v-show="showOrderTotalDiscountAmount">
                <span>
                  {{ translate("PreviewOrder:OrderTotalDiscountAmountLabel") }}:
                </span>
                <span> {{ order.orderTotalDiscountAmount.formatted }} </span>
              </li>
              <li>
                <span> {{ translate("PreviewOrder:FreightCost") }}: </span>
                <span> {{ order.freightCost.formatted }} </span>
              </li>
              <li>
                <span>
                  {{ translate("PreviewOrder:OrderTotalExclVat") }}:
                </span>
                <span>{{ order.orderTotalExclVat.formatted }}</span>
              </li>
              <li>
                <span> {{ translate("PreviewOrder:OrderVat") }}: </span>
                <span> {{ order.orderVat.formatted }} </span>
              </li>
              <li class="preview-order__price">
                <span>
                  {{
                    translate("PreviewOrder:OrderTotalInclVatAndFreightCost")
                  }}:
                </span>
                <span>
                  {{ order.orderTotalInclVatAndFreightCost.formatted }}
                </span>
              </li>
            </ul>
          </div>

          <div v-if="errorMessage">
            {{ errorMessage }}
          </div>

          <div
            class="preview-order__action-btns"
            v-if="orderStatusSentOrPlaced"
          >
            <button  v-show="showDownloadOrderImages" @click="downloadOrderImages" class="btn__default--primary download-img">
            
              <span class="download-img__container">
                {{ $t("PreviewOrder:DownloadOrderImages") }}
                <img
                      src="../../assets/icons/download.svg"
                      class="custom-svgs"
                      alt=""
                />
              </span>
            </button>
            
            <!-- Button - Change offer -->
            <button
              v-if="showChangeOrder"
              @click="changeOrder"
              class="btn__default--primary"
            >
              {{ $t("PreviewOrder:EditOrder") }}
            </button>

            <!-- Button - Place order - step 1 -->
            <button
              v-if="showPlaceOrderStep1ShowDeliveryAddress"
              @click="() => setCallBackFunctionAndShowHideCustomerInfo()"
              class="btn__default--primary"
            >
              {{ $t("PreviewOrder:AddDeliveryAddress") }}
            </button>

            <!-- Button - Copy to new offer -->
            <button
              v-if="showCopyOrder"
              @click="createOrderCopy"
              class="btn__default--primary"
            >
              {{ $t("PreviewOrder:CopyToNewOrder") }}
            </button>
          </div>
          <div
            v-if="showCustomerInformation"
            class="preview-order__delivery-container"
          >
            <div class="preview-order__delivery-content">
              <button
                class="btn__round btn__with-icon btn__with-icon--close preview-order__delivery-close"
                @click="() => showHideCustomerInformation()"
              ></button>

              <customer-info-form
                :callback-function="callbackFunction"
                :customer-information="customerInformation"
                :weekNumber="weekNumber"
                :country-list="countryList"
                :update-week-number="updateWeekNumber"
                :is-complete="isComplete"
                :is-external-selected="isExternalSelected"
                  />
                  </div>
                </div>
        </div>
      </div>
    </div>
    <div
      class="wizard-bottom-bar__container preview-order__bottom-container"
      v-show="orderStatusInitiated"
    >
     <button v-show="showDownloadOrderImages" @click="downloadOrderImages" class="btn__default--primary download-img">           
              <span class="download-img__container">
                 {{ $t("PreviewOrder:DownloadOrderImages") }}
                <img
                      src="~@/assets/icons/download.svg"
                      class="custom-svgs"
                      alt=""
                />
              </span>
      </button>     
     <!-- Button - Send offer PDF -->
      <button
        class="btn__default--primary"
        @click="sendOrderPdf()"
        v-if="showSendOrderPdf"
      >
        {{ translate("PreviewOrder:SendOrderPdf") }}
      </button>
      
      <div v-if="showEmailSendResponse">
        <div>{{ emailSendResponseText }}</div>
        <button @click="closeEmailSendResponseBox()">
          {{ translate("OrderPdf:SendEmailSuccessBackButtonText") }}
        </button>
      </div>
      
      <button v-if="hasExternalFabrics"
        class="btn__default--primary external-fabric-button"
        @click="() => setCallBackFunctionAndShowHideCustomerInfo(true)"
        :disable="!hasExternalFabricsSelected"
        :class="{ btn__disabled: !hasExternalFabricsSelected, disable__button: !hasExternalFabricsSelected }"
      >
        {{ translate("PreviewOrder:SendExternalFabricsPdf") }}
      </button>  
    </div>
  </div>
</template>

<script src="./PreviewOrder.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./PreviewOrder.scss";
</style>
