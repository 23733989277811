import { ConfigurationType, SewTogetherLoadStatus, CurtainDrop, CurtainOrientationType, FabricEditorSelectedData, MarkingPriceRequest } from "@/models/configuratormodels";
import { SewingType } from "@/models/configuratormodels";
import { Options, Vue } from "vue-class-component";
import { ConfiguratorService } from "@/axios/configuratorservice";
import { CurtainParameter } from "@/models/configuratormodels";
import { Prop, Watch } from "vue-property-decorator";
import { CalculateCurtainDropsApiOutput, CalculateCurtainDropsApiInput } from "@/models/configuratormodels";
import { PriceService } from "@/axios/priceservice";
import { UserFriendlyError } from "@/models/errormodels";
import { Marking, Order, OrderLine } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import { RouteParams } from "@/router/RouteParams";
import { useRoute } from "vue-router";
import { ProductModel } from "@/models/stormmodels";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import debounce from 'lodash.debounce'
import { OrderService } from "@/services/OrderService";


@Options({
    emits: [
        "calculating",
        "calculated",
        "show-tip-messages"
    ],
    components: {
        VueSlider
    }
})
export default class FabricEditor extends Vue {

    protected isWatchActive: boolean = false;

    @Prop()
    public orderLine!: OrderLine

    @Prop()
    public selectedVariant!: ProductModel

    @Prop()
    public show!: boolean

    mounted(): void {
        this.onResize();
        window.addEventListener('resize', debounce(this.onResize, 500));
    }

    unmounted(): void {
        document.body.classList.remove("compact-view");
        window.removeEventListener('resize', this.onResize);
    }

    @Watch('show')
    onShowChange(): void {
        this.onResize();
    }

    private onResize() {
        document.body.classList.toggle("compact-view", this.show && this.isCompactView());
    }

    private isCompactView(): boolean {
        return document.body.clientHeight < 888;
    }

    protected order(): Order {
        const order = OrderStore.order;
        if (order == null) {
            throw "Order not found";
        }
        return order;
    };

    protected route = useRoute();

    protected getMarking(): Marking {
        const markingId = new RouteParams(this.route.params).marking();
        const marking = this.order().markings.get(markingId);
        if (!marking) {
            throw "Marking not found";
        }
        return marking;
    };

    protected getCurtainPriceText(curtainDrop: CurtainDrop): string {
        if (!curtainDrop?.pricePerCurtainDrop) {
            return "";
        }
        return `${curtainDrop.pricePerCurtainDrop.formatted} / ${this.$t("ConfiguratorEditor:PricePerDropsText")}`;
    }

    protected getTotalCurtainPriceText(calculateCurtainDrop: CalculateCurtainDropsApiOutput): string {
        if (!calculateCurtainDrop?.totalPriceForMarking) {
            return "";
        }
        return calculateCurtainDrop.totalPriceForMarking.formatted;
    }

    protected configurationTypeList: ConfigurationType[] = [ConfigurationType.Simple, ConfigurationType.CoverWall];
    protected configurationTypeSelectedBool: boolean = false;

    protected wallHeightMinAllowed: number = 0;
    protected wallHeightMaxAllowed: number = 1000;
    protected wallHeightSelected: number = 200;

    protected wallWidthMinAllowed: number = 0;
    protected wallWidthMaxAllowed: number = 1000;
    protected wallWidthSelected: number = 150;

    protected showHeightDeduction: boolean = false;

    protected heightDeductionMaxAllowed: number = 100; // prefill
    protected heightDeductionMinAllowed: number = 0; // prefill
    protected heightDeduction: number = 0; // prefill
    protected splitCurtain: boolean = false;
    protected sewTogether: boolean = false;
    protected orientationLaying: boolean = false;
    protected useWeights: boolean = false;
    protected errorMessages: string = "";
    protected readonly parameters: Map<string, CurtainParameter> = new Map();
    protected quantity: number = 1;
    protected quantityMinAllowed: number = 1;
    protected quantityMaxAllowed: number = 50;

    protected calculatedCurtainDrops: CalculateCurtainDropsApiOutput = {} as CalculateCurtainDropsApiOutput;
    protected sewingTypeList: SewingType[] = [SewingType.Wave80, SewingType.Wave60, SewingType.GliderSinglePleat, SewingType.CurtainHeaderTape, SewingType.Glider];
    protected sewingTypeSelected: SewingType = SewingType.Wave80;
    protected tipMessages: string[] = [];

    //Watch the user inputs and make the calculate curtain
    @Watch("sewingTypeSelected")
    onPropertyChangedSewingType(): void {
        if (!this.heightDeductionChange()) {
            this.calculateCurtainDrops();
        }
    }

    @Watch("selectedVariant")
    onPropertyChangedSelectedVariant(): void { 
        this.sewTogetherAutoSelectWhenSelectVariant();
        this.calculateCurtainDrops();
    }

    protected sewTogetherLoadStatus : SewTogetherLoadStatus = SewTogetherLoadStatus.FirstTimeLoaded;
    protected sewTogetherAutoSelectWhenSelectVariant(): void {
        if(this.orderLine.curtainData == null) {  // New item
            this.sewTogetherLoadStatus = SewTogetherLoadStatus.CanAutoLoad;
        }
        if(this.orderLine.curtainData != null && this.sewTogetherLoadStatus == SewTogetherLoadStatus.FirstTimeLoaded){ // Saved item
            this.sewTogetherLoadStatus = SewTogetherLoadStatus.CanNotAutoLoad; // saved item, first time then do not auto reload sewTogether
        }
        if(this.sewTogetherLoadStatus == SewTogetherLoadStatus.CanNotAutoLoad){ // saved item, Firstime not auto select sewTogether, then enable auto select next time onwards
            this.sewTogetherLoadStatus = SewTogetherLoadStatus.CanAutoLoad;
        }
        else{
            this.sewTogetherAutoSelect();   
        }
    }

    @Watch("orientationLaying")
    @Watch("sewTogether")
    @Watch("splitCurtain")
    @Watch("useWeights")
    onPropertyChanged(): void {
        this.calculateCurtainDrops();
    }

    @Watch("configurationTypeSelectedBool")
    onPropertyChangedConfigurationType(): void {
        this.sewTogetherAutoSelect();
        this.calculateCurtainDrops();
    }

    protected sewTogetherAutoSelect() : void {
        if(this.selectedConfigurationType == ConfigurationType.CoverWall  && this.selectedVariant?.width == 150){
            this.sewTogether = true;
            this.orientationLaying = false;
        }
    }

    @Watch("wallHeightSelected")
    onPropertyChangedWallHeight(value: number): void {
        if (value >= this.wallHeightMinAllowed && value <= this.wallHeightMaxAllowed) {
            this.calculateCurtainDrops();
        }
    }

    @Watch("wallWidthSelected")
    onPropertyChangedWallWidth(value: number): void {
        if (value >= this.wallWidthMinAllowed && value <= this.wallWidthMaxAllowed) {
            this.calculateCurtainDrops();
        }
    }

    @Watch("heightDeduction")
    onPropertyChangedHeightDeduction(value: number): void {
        if (!value || isNaN(value) || value < this.heightDeductionMinAllowed) {
            this.heightDeduction = this.heightDeductionMinAllowed;
        } else if (value > this.heightDeductionMaxAllowed) {
            this.heightDeduction = this.heightDeductionMaxAllowed;
        }
        
        const order = this.order();
        if (order.isNewDeductionType == "True") {
            this.heightDeduction = Math.floor(this.heightDeduction);
        }

        this.calculateCurtainDrops();
    }

    @Watch("quantity")
    onPropertyChangedQuantity(value: number): void {
        if (value < 1) {
            this.quantity = 1;
        }
        this.calculateCurtainDrops();
    }

    protected validateWallWidthSelected(): void {
        if (this.wallWidthSelected < this.wallWidthMinAllowed) {
            this.wallWidthSelected = this.wallWidthMinAllowed;
        } else if (this.wallWidthSelected > this.wallWidthMaxAllowed) {
            this.wallWidthSelected = this.wallWidthMaxAllowed;
        }
    }

    protected validateWallHeightSelected(): void {
        if (this.wallHeightSelected < this.wallHeightMinAllowed) {
            this.wallHeightSelected = this.wallHeightMinAllowed;
        } else if (this.wallHeightSelected > this.wallHeightMaxAllowed) {
            this.wallHeightSelected = this.wallHeightMaxAllowed;
        }
    }

    protected get selectedConfigurationType(): ConfigurationType {
        return this.configurationTypeSelectedBool ? ConfigurationType.CoverWall : ConfigurationType.Simple;
    }

    protected wallWidthSliderOnDragEnd(): void {
        (this.$refs.wallWidthSlider as HTMLElement)?.blur();
    }

    protected wallHeightSliderOnDragEnd(): void {
        (this.$refs.wallHeightSlider as HTMLElement)?.blur();
    }

    protected heightDeductionChange(): boolean {
        if (!this.isWatchActive) {
            return false;
        }
        const value = this.heightDeduction;
        const parameterKey = SewingType[this.sewingTypeSelected] + ".DeductionWallheightToFloor";
        this.heightDeduction = this.parameters.get(parameterKey)?.value ?? 0;

        return this.heightDeduction !== value;
    }

    // to avoid empty textbox value error
    protected isNull(value: number): number {
        if (!!value && !isNaN(value)) {
            return value
        }
        else {
            return 0;
        }
    }

    private calculateCurtainDrops(): void {

        const isExternalFabric = !!this.selectedVariant?.isExternalFabric;

        if(isExternalFabric)
        {
            if (!this.selectedVariant?.partNo && !this.selectedVariant?.width) {
                return;
            }
        }
        else {
            if (!this.selectedVariant?.partNo || !this.selectedVariant?.configurableProductId || this.selectedVariant.partNo === 0 || this.selectedVariant.configurableProductId === 0) {
                return;
            }
        }
        
        this.$emit("calculating", true);

        const input: CalculateCurtainDropsApiInput = {
            fabricName: this.selectedVariant?.name,
            fabricWidth: this.selectedVariant?.width,
            fabricReport: this.selectedVariant?.repeatHeight,
            fabricRecommendWeights: this.selectedVariant?.recommendWeights,
            curtainConfiguration: this.selectedConfigurationType as number,
            quantity: this.isNull(this.quantity) as number,
            heightDeduction: this.isNull(this.heightDeduction),
            sewingType: this.sewingTypeSelected as number,
            wallHeight: this.isNull(this.wallHeightSelected) as number,
            wallWidth: this.isNull(this.wallWidthSelected) as number,
            orientation: !this.showOrientationLaying() ? 1 : (this.orientationLaying ? 0 : 1), // Send Orentation as 0(Laying) if selected, else send 1(default)
            sewTogether: this.selectedConfigurationType == ConfigurationType.CoverWall ? this.sewTogether : false, // SewTogeter is not allowed in simple config
            splitCurtain: this.selectedConfigurationType == ConfigurationType.CoverWall ? false : this.splitCurtain,
            useWeights: this.useWeights,
            preferredOrientation: this.selectedVariant?.preferredOrientation 
        };
        
        ConfiguratorService.calculateCurtainDrops(input).then((data) => {
            this.errorMessages = "";
            this.calculatedCurtainDrops = data;
            this.tipMessages = data.tipMessages;  

            this.$emit("show-tip-messages", data.tipMessages);

            const selectedData = {
                curtainConfiguration: this.selectedConfigurationType,
                wallHeight: this.wallHeightSelected,
                wallWidth: this.wallWidthSelected,
                heightDeduction: this.isNull(this.heightDeduction),
                sewTogether: this.sewTogether,
                splitCurtain: this.splitCurtain,
                useWeights: this.useWeights
            } as FabricEditorSelectedData;

            // Only make pricecalculations if not externalfabric
            if (!isExternalFabric) {
            const markingPriceRequest: MarkingPriceRequest = {
                orderId: this.order().id,
                markingId: this.getMarking().id,
                markingQuantity: this.quantity,
                erpProductId: this.selectedVariant?.partNo,
                erpConfigurableProductId: this.selectedVariant?.configurableProductId,
                curtainConfiguration: input.curtainConfiguration,
                curtainDrops: this.calculatedCurtainDrops.curtainDrops,
                finishedHeight: this.calculatedCurtainDrops.finishedHeight,
                orientation: input.orientation,
                numberOf2FingerHooks: this.calculatedCurtainDrops.numberOf2FingerHooks,
                numberOf4FingerHooks: this.calculatedCurtainDrops.numberOf4FingerHooks,
                sewTogether: this.calculatedCurtainDrops.sewTogether,
                sewingType: this.calculatedCurtainDrops.sewingType,
                splitCurtain: this.calculatedCurtainDrops.splitCurtain,
                currencyCode: this.order().currencyCode,
                underHem: this.calculatedCurtainDrops.underHem
            };

            PriceService.getMarkingPrice(markingPriceRequest).then(async (markingPrice) => {
                for (let i = 0; i < this.calculatedCurtainDrops.curtainDrops.length; i++) {
                    this.calculatedCurtainDrops.curtainDrops[i].pricePerCurtainDrop = markingPrice.priceLines[i]?.price;
                }
                this.calculatedCurtainDrops.totalPriceForMarking = markingPrice.totalPrice;

                // When the creating order, the currencyCode will be empty
                // So update the correct currencyCode of the customer when fetch price first time
                // Refresh the order to sync the currency and formating of all the prices
                const order = this.order();
                if (markingPrice.totalPrice.currencyCode && order.currencyCode != markingPrice.totalPrice.currencyCode) {
                    await OrderService.loadOrder(order.id);
                }
                this.$emit("calculated", data, selectedData);
            });
        } else {
            this.$emit("calculated", data, selectedData);
        }
        }, (details: UserFriendlyError) => {
            this.calculatedCurtainDrops = {} as CalculateCurtainDropsApiOutput;
            this.errorMessages = details.message;
            this.$emit("calculated");
        });
    }

    protected showWallWidth(): boolean {
        return this.selectedConfigurationType === ConfigurationType.CoverWall;
    }

    protected showTipMessages(): boolean {
        return this.tipMessages.length > 0;
    }

    protected showErrorMessages(): boolean {
        return !!this.errorMessages;
    }

    protected getConfigurationTypeName(type: ConfigurationType): string {
        return ConfigurationType[type];
    }

    protected getSewingTypeName(type: SewingType): string {
        return SewingType[type];
    }

    protected showSplitCurtain(): boolean {
        return (this.selectedVariant?.width ?? 0) == 300 && this.selectedConfigurationType == ConfigurationType.Simple;
    }

    protected showSewTogether(): boolean {
        return this.selectedConfigurationType == ConfigurationType.CoverWall && !this.orientationLaying;
    }

    protected showWidthCovered(): boolean {
        return this.selectedConfigurationType == ConfigurationType.Simple && !this.splitCurtain;
    }

    protected orientationLayingCondition(): boolean {
        return (this.selectedVariant?.width ?? 0) == 300 && this.selectedConfigurationType == ConfigurationType.CoverWall;
    }

    protected showOrientationLaying(): boolean {
        return this.orientationLayingCondition() && !this.sewTogether;
    }

    protected showCoverWallCheckBoxContainer(): boolean {
        return this.selectedConfigurationType as number === 1;
    }

    protected showSimpleWallCheckboxContainer(): boolean {
        return (this.selectedConfigurationType as number === 0);
    }

    private setOrderLine = (id: string): void => {
        const orderLine = this.order().orderLines.find((item) => item.markingId === id);
        this.orderLine = orderLine as OrderLine;
    }

    private populateData(orderLine: OrderLine): void {
        this.configurationTypeSelectedBool = orderLine.curtainConfiguration == ConfigurationType.CoverWall;

        let quantity = orderLine.quantity ?? 0;
        if ((orderLine?.markingQuantity ?? 0) > 0) {
            quantity = orderLine.markingQuantity ?? 0;
        }

        this.quantity = quantity;
        this.wallHeightSelected = orderLine.wallHeight == 0 ? 200 : orderLine.wallHeight;
        this.wallWidthSelected = orderLine.wallWidth ?? this.wallWidthMinAllowed;
        this.heightDeduction = orderLine.heightDeduction ?? 0;

        if (orderLine.curtainData != null) {
            this.orientationLaying = orderLine.curtainData.orientation === CurtainOrientationType.Lying;
            this.sewingTypeSelected = (orderLine.curtainData.sewingType ?? SewingType.Wave80);
            this.sewTogether = orderLine.curtainData.sewTogether;
            this.splitCurtain = orderLine.curtainData.splitCurtain;

            if (this.splitCurtain && orderLine.markingQuantity != null && orderLine.markingQuantity > 0) { // in the case of split curtain take half, only if pre populate  already saved data
                if (this.quantity % 2 == 0) {
                    this.quantity = this.quantity / 2;
                }
            }
            this.useWeights = orderLine.curtainData.totalNumberOfWeights > 0;
        }
    }

    private async loadParameters(): Promise<void> {
        this.parameters.clear();
        try {
            const parameters = await ConfiguratorService.getParameters();
            Object.entries(parameters).forEach(([name, value]) => {
                this.parameters.set(name, value);
            });
            this.wallHeightMinAllowed = this.parameters.get("MinAllowedWallHeight")?.value ?? 0;
            this.wallHeightMaxAllowed = this.parameters.get("MaxAllowedWallHeight500")?.value ?? 0;
            this.wallWidthMinAllowed = this.parameters.get("MinAllowedWallWidth")?.value ?? 0;
            const marking = this.getMarking();

            if (marking && marking.id) {
                this.setOrderLine(marking.id);
                if (this.orderLine == null) {
                    throw "Orderline not found"
                }
                this.isWatchActive = false;
                this.populateData(this.orderLine);
                if (this.orderLine.heightDeduction === undefined) {
                    this.isWatchActive = true;
                }
                else {
                    setTimeout(() => { this.isWatchActive = true; }, 500);
                }
                this.heightDeductionChange();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async created(): Promise<void> {
        await this.loadParameters();
    }
};
