<template>
<Form class="preview-order__form" @submit="callbackFunction">
    <h2>{{ $t("PreviewOrder:AddDeliveryAddress") }}</h2>
    <div class="input__container"
      v-if="!isExternalSelected">
      <label
        class="input__label--small input__label"
        for="customerOrderNumber"
        >{{ $t("PreviewOrder:CustomerOrderNumber") }}</label
      >
      <Field
        id="customerOrderNumber"
        name="customerOrderNumber"
        as="input"
        type="text"
        :label="$t('PreviewOrder:CustomerOrderNumber')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.customersOrderNumber"
      />
      <ErrorMessage
        name="customerOrderNumber"
        class="input__error-msg"
      />
    </div>
    <div class="input__container"
      v-if="isExternalSelected">
      <label
        class="input__label--small input__label"
        for="deliveryAddressCompanyName"
        >{{ $t("PreviewOrder:DeliveryAddressCompanyName") }}</label
      >
      <Field
        id="deliveryAddressCompanyName"
        name="deliveryAddressCompanyName"
        as="input"
        type="text"
        :label="$t('PreviewOrder:DeliveryAddressCompanyName')"
        autocomplete="off"
        v-model="customerInformation.company"
      />
      <ErrorMessage
        name="deliveryAddressCompanyName"
        class="input__error-msg"
      />
    </div>
    <div class="input__container" 
      v-if="isExternalSelected">
      <label
        for="firstName"
        class="input__label--small input__label"
        >{{ $t("ExternalCustomerInfo:FirstName") }}</label
      >
      <Field
        id="firstName"
        name="firstName"
        as="input"
        type="text"
        :label="$t('ExternalCustomerInfo:FirstName')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.firstName"
      />
      <ErrorMessage name="firstName" class="input__error-msg" />
    </div>
    <div class="input__container"
      v-if="isExternalSelected">
      <label
        for="lastName"
        class="input__label--small input__label"
        >{{ $t("ExternalCustomerInfo:LastName") }}</label
      >
      <Field
        id="lastName"
        name="lastName"
        as="input"
        type="text"
        :label="$t('ExternalCustomerInfo:LastName')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.lastName"
      />
      <ErrorMessage name="lastName" class="input__error-msg" />
    </div>
    <div class="input__container"
      v-if="!isExternalSelected">
      <label
        class="input__label--small input__label"
        for="requestedDeliveryDate"
        >{{ $t("PreviewOrder:RequestedDeliveryDate") }}</label
      >
      <Field
        id="requestedDeliveryDate"
        name="requestedDeliveryDate"
        as="input"
        type="date"
        :label="$t('PreviewOrder:RequestedDeliveryDate')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.requestedDeliveryDate" @change="updateWeekNumber($event)"
      />
      {{$t("PreviewOrder:RequestedDeliveryWeek")}}: {{ weekNumber }}
      <ErrorMessage
        name="requestedDeliveryDate"
        class="input__error-msg"
      />
    </div>
    <div class="input__container">
      <label
        class="input__label--small input__label"
        for="cellPhone"
        >{{ $t("PreviewOrder:CellPhone") }}</label
      >
      <Field
        id="cellPhone"
        name="cellPhone"
        as="input"
        type="number"
        :label="$t('PreviewOrder:CellPhone')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.cellPhone"
      />
      <ErrorMessage name="cellPhone" class="input__error-msg" />
    </div>
    <div class="input__container">
      <label
        class="input__label--small input__label"
        for="mobile"
        >{{ $t("ExternalFabricOrderPdf:HeaderMobile") }}</label
      >
      <Field
        id="mobile"
        name="mobile"
        as="input"
        type="number"
        :label="$t('ExternalFabricOrderPdf:HeaderMobile')"
        autocomplete="off"
        v-model="customerInformation.mobile"
      />
      <ErrorMessage name="mobile" class="input__error-msg" />
    </div>
    <div class="input__container"
      v-if="!isExternalSelected">
      <label
        class="input__label--small input__label"
        for="deliveryAddressCompanyName"
        >{{ $t("PreviewOrder:DeliveryAddressCompanyName") }}</label
      >
      <Field
        id="deliveryAddressCompanyName"
        name="deliveryAddressCompanyName"
        as="input"
        type="text"
        :label="$t('PreviewOrder:DeliveryAddressCompanyName')"
        autocomplete="off"
        v-model="customerInformation.deliveryAddressCompanyName"
      />
      <ErrorMessage
        name="deliveryAddressCompanyName"
        class="input__error-msg"
      />
    </div>
    <div class="input__container">
      <label
        class="input__label--small input__label"
        for="address1"
        >{{ $t("PreviewOrder:AddressLine1") }}</label
      >
      <Field
        id="address1"
        name="address1"
        as="input"
        type="text"
        :label="$t('PreviewOrder:AddressLine1')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.address1"
      />
      <ErrorMessage name="address1" class="input__error-msg" />
    </div>
    <div class="input__container"
      v-if="!isExternalSelected">
      <label
        class="input__label--small input__label"
        for="address1"
        >{{ $t("PreviewOrder:AddressLine2") }}</label
      >
      <Field
        id="address1"
        name="address2"
        as="input"
        type="text"
        :label="$t('PreviewOrder:AddressLine2')"
        autocomplete="off"
        v-model="customerInformation.address2"
      />
      <ErrorMessage name="address2" class="input__error-msg" />
    </div>
    <div class="input__container">
      <label
        class="input__label--small input__label"
        for="zipcode"
        >{{ $t("PreviewOrder:ZipCode") }}</label
      >
      <Field
        id="zipcode"
        name="zipcode"
        as="input"
        type="number"
        :label="$t('PreviewOrder:ZipCode')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.zipcode"
      />
      <ErrorMessage name="zipcode" class="input__error-msg" />
    </div>
    <div class="input__container">
      <label class="input__label--small input__label" for="city">{{
        $t("PreviewOrder:City")
      }}</label>
      <Field
        id="city"
        name="city"
        as="input"
        type="text"
        :label="$t('PreviewOrder:City')"
        rules="required"
        autocomplete="off"
        v-model="customerInformation.city"
      />
      <ErrorMessage name="city" class="input__error-msg" />
    </div>
    <div class="input__container">
      <label
        for="email"
        class="input__label--small input__label"
        >{{ $t("Login:EmailAddress") }}</label
      >
      <Field
        id="email"
        name="email"
        as="input"
        type="email"
        :label="$t('Login:EmailAddress')"
        rules="required|email"
        autocomplete="off"
        v-model="customerInformation.email"
      />
      <ErrorMessage name="email" class="input__error-msg" />
    </div>    
    <div class="input__container">
      <label
        class="input__label--small input__label"
        for="country"
        >{{ $t("PreviewOrder:Country") }}</label
      >
      <div class="dropdown__container">
        <select
          class="dropdown"
          id="country"
          v-model="customerInformation.country"
        >
          <option
            v-for="country in countryList"
            :key="country.code"
            :value="country.code"
            :selected="country.code === customerInformation.country"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>    
    <!-- Button - Place order - step 2 -->
    <button      
      class="btn__default--primary"
      type="submit"
    >
      {{ !isExternalSelected ?  $t("PreviewOrder:SendToERP") : $t("PreviewOrder:SendExternalFabricsPdf") }}
    </button>
  </Form>
</template>

<script src="./CustomerInfoForm.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./PreviewOrder.scss";
</style>